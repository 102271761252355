.drawermenuOwcdrawer {
  background: var(--one-color-cobas-white);
  z-index: 1100;
}
.drawermenuPanel {
  width: 278px;
  height: 100%;
  max-width: 100%;
  z-index: 9999;
}
.drawermenuHeader {
  width: 278px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: thin solid var(--one-color-interaction-disabled-neutral-3);
}
.drawermenuMenuText {
  display: flex;
  align-items: center;
}
