.appbarUserLogo {
  border-radius: 100%;
  background-color: #037b84;
  font-size: 20px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  margin-bottom: 12px;
}
.appbarUserName {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  margin-bottom: 4px;
  text-transform: capitalize;
}
.appbarUserEmail {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
}
.appbarUserInfoSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appbarLogoLetter {
  background: var(--one-color-interaction-default-brand-1);
  color: var(--one-color-interaction-default-base-1);
  font-size: 24px;
  padding: 16px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: var(--one-text-font-family-default);
}
.appbarInfoUserInfo {
  margin-right: 25px;
  display: flex;
  align-items: center;
}
.VerticalLine {
  margin-left: 12px;
  width: 1px;
  height: 20px;
  background: var(--one-color-cobas-gray-600);
}
