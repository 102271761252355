.ag-theme-DLab {
  --ag-foreground-color: var(--one-color-interaction-default-neutral-4);
  --ag-background-color: var(--one-color-cobas-white);

  --ag-header-foreground-color: var(--one-color-interaction-default-neutral-4);
  --ag-header-background-color: var(--one-color-cobas-gray-100);
  --ag-header-height: 40px;

  --ag-odd-row-background-color: var(--one-color-cobas-white);
  --ag-data-color: var(--one-color-interaction-default-neutral-4);

  --ag-row-border-color: var(--one-color-cobas-gray-600);
  --ag-border-color: var(--one-color-cobas-gray-600);
  --ag-font-size: 16px;
  --ag-row-height: 40px;
  --ag-list-item-height: 40px;

  --ag-material-accent-color: var(--one-color-cobas-blue-600);

  --ag-control-panel-background-color: var(--one-color-cobas-white);
  --ag-subheader-background-color: var(--one-color-cobas-white);

  --ag-range-selection-highlight-color: rgb(60, 188, 0, 0.3);
}
/* Commented for future refrence */
/* .ag-row-group {
  background-color: var(--one-color-cobas-white);
} */
.ag-pinned-right-header {
  border-left: var(--one-border-none);
}
/* .ag-pinned-left-header {
  border-right: var(--one-border-none);
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
} */

/* To hide border in pinned cluster column  */
.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
  border-right: thin solid transparent;
}
/* To hide border in pinned action column  */
.ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
  border-left: thin solid transparent;
}
.ag-header-row {
  height: 40px !important;
  min-height: 40px !important;
}
.ag-header-cell-label {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ag-side-buttons {
  width: 46px;
  border: thin solid var(--one-color-cobas-gray-400);
}
.ag-tool-panel-wrapper {
  border: thin solid var(--one-color-cobas-gray-400);
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 40%) 0px 0px 3px;
}
.ag-theme-material .ag-column-select,
.ag-theme-material .ag-column-select-header {
  border-bottom: thin solid var(--one-color-cobas-gray-400);
}
.ag-theme-material .ag-column-select,
.ag-theme-material .ag-column-select-header {
  border: var(--one-border-none);
}

.ag-theme-material .ag-column-panel-column-select {
  border: var(--one-border-none);
}
.ag-column-select-virtual-list-item {
  border-bottom: thin solid var(--one-color-cobas-gray-400);
}
.ag-filter-toolpanel-group-wrapper {
  border-bottom: thin solid var(--one-color-cobas-gray-400);
}
.ag-filter-virtual-list-item {
  background-color: var(--one-color-cobas-white);
  border-bottom: thin solid var(--one-color-cobas-gray-400);
}
.ag-side-button.ag-selected .ag-side-button-button .ag-icon,
.ag-side-button.ag-selected .ag-side-button-button span,
.ag-side-button.ag-selected .ag-side-button-button .ag-side-button-label,
.ag-side-button.ag-selected .ag-side-button-button .ag-side-button-icon-wrapper,
.ag-side-button.ag-selected .ag-side-button-button .ag-side-button-icon-wrapper svg path {
  color: var(--one-color-blue-900);
  stroke: var(--one-color-blue-900);
}
.ag-side-button-button .ag-icon-filter {
  transform: rotate(90deg);
}
/* .ag-tool-panel-wrapper {
  min-width: 500px;
} */

.ag-react-container {
  width: 100%;
}

.ag-filter-apply-panel-button {
  background-color: var(--one-color-blue-900) !important;
  padding: 6px 16px !important;
  font-size: 0.875rem !important ;
  text-transform: none !important;
  border-radius: var(--one-border-radius-4) !important;
  cursor: pointer !important;
  color: var(--one-color-cobas-white) !important;
  border: thin solid rgba(0, 102, 204, 0.5) !important;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

/* .ag-pinned-left-cols-container {
  min-width: 80px !important;
  width: 80px !important;
} */

/* .ag-horizontal-left-spacer {
  width: 80px !important;
  max-width: 80px !important;
  min-width: 80px !important;
} */
